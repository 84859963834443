import { ApolloCache, LazyQueryExecFunction } from '@apollo/client';
import {
  AllAppointmentsInput,
  Appointment,
  AppointmentInput,
  Exact,
} from '@smart/adb-shared';
import {
  AllAppointmentsDocument,
  AllAppointmentsQuery,
} from 'graphql/queries/appointments.generated';
import { AppointmentQuery } from 'pages/tasks/tasks/queries.generated';

type AppointmentCacheHelperParams<T> = {
  cache: ApolloCache<T>;
  appointmentId: string | undefined;
  input: AllAppointmentsInput;
};

type AppointmentCacheWithFetch<T> = AppointmentCacheHelperParams<T> & {
  getAppointment: LazyQueryExecFunction<
    AppointmentQuery,
    Exact<{
      input: AppointmentInput;
    }>
  >;
};

const getCachedAppointments = <T>(
  cache: ApolloCache<T>,
  input: AllAppointmentsInput
): Appointment[] => {
  const data = cache.readQuery<AllAppointmentsQuery>({
    query: AllAppointmentsDocument,
    variables: { input },
  });

  return data?.allAppointments.appointments ?? [];
};

export const addNewAppointmentToGqlCache = async <T>({
  cache,
  appointmentId,
  input,
  getAppointment,
}: AppointmentCacheWithFetch<T>) => {
  if (!appointmentId)
    throw new Error("Could not fetch appointment, missing 'appointmentId'");

  const { data } = await getAppointment({
    variables: {
      input: { id: appointmentId },
    },
  });
  if (!data?.appointment) throw new Error('No appointment data was found');

  const appointments = getCachedAppointments(cache, input);

  cache.writeQuery({
    query: AllAppointmentsDocument,
    variables: {
      input,
    },
    data: {
      allAppointments: {
        appointments: appointments.concat([data?.appointment]),
      },
    },
  });
};

export const updateAppointmentInGqlCache = async <T>({
  cache,
  appointmentId,
  input,
  getAppointment,
}: AppointmentCacheWithFetch<T>) => {
  if (!appointmentId)
    throw new Error(
      "Could not fetch updated appointment, missing 'appointmentId'"
    );

  const { data } = await getAppointment({
    variables: {
      input: { id: appointmentId },
    },
  });
  if (!data?.appointment) throw new Error('No appointment data was found');

  const appointments = getCachedAppointments(cache, input);

  const oldAppointmentsArray =
    appointments.filter(
      (oldAppointment) => data.appointment.id !== oldAppointment.id
    ) ?? [];

  cache.writeQuery({
    overwrite: true,
    query: AllAppointmentsDocument,
    variables: {
      input,
    },
    data: {
      allAppointments: {
        appointments: oldAppointmentsArray.concat([data.appointment]),
      },
    },
  });
};

export const removeCanceledAppointmentFromGqlCache = async <T>({
  cache,
  appointmentId,
  input,
}: AppointmentCacheHelperParams<T>) => {
  if (!appointmentId)
    throw new Error(
      "Could not remove appointment from gql cache, missing 'appointmentId'"
    );

  const appointments = getCachedAppointments(cache, input);

  const activeAppointments =
    appointments.filter(
      (oldAppointment) => appointmentId !== oldAppointment.id
    ) ?? [];

  cache.writeQuery({
    overwrite: true,
    query: AllAppointmentsDocument,
    variables: {
      input,
    },
    data: {
      allAppointments: {
        appointments: activeAppointments,
      },
    },
  });
};
