import {
  getPageNameFromPathname,
  pageNameToPageTypeMap,
  pushToDataLayer,
} from '@utils/analytics/pushToDataLayer';
import { getCurrencyByMarket } from '@utils/getCurrencyByMarket';
import { useCurrentOutlet } from 'hooks/outlet';
import {
  type PropsWithChildren,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAgentContext } from './agent-context';
import { useLanguageContext } from './language-context';
import { useMarketContext } from './market-context';

export const PageViewTrackContext = createContext(null);

export const PageViewTrackContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { DEPLOY_ENV, BUILDKITE_COMMIT } = import.meta.env;
  const location = useLocation();
  const [referrerPath, setReferrerPath] = useState<string | null>(null);

  const { agent } = useAgentContext();
  const { market } = useMarketContext();
  const { locale } = useLanguageContext();
  const outlet = useCurrentOutlet();

  const previousPathnameRef = useRef(location.pathname);

  useEffect(
    () => {
      const pageName = getPageNameFromPathname(location.pathname);

      if (
        agent &&
        pageName &&
        outlet &&
        previousPathnameRef.current !== location.pathname
      ) {
        pushToDataLayer({
          event: 'pageview',
          page: {
            name: pageName,
            type: pageNameToPageTypeMap[pageName],
            referringPage: referrerPath,
            url: window.location.href,
          },
          user: {
            loginStatus: 'logged_in',
            customerId: agent?.id,
            type: 'agent',
            channel: 'adb',
            outlet: [
              {
                id: outlet?.bpId ?? '',
                name: outlet?.name ?? '',
              },
            ],
          },
          application: {
            id: 'adb',
            version: BUILDKITE_COMMIT,
            env: DEPLOY_ENV,
            language: locale.split('-')[0],
            market,
            currency: getCurrencyByMarket(market),
          },
        });
      }

      setReferrerPath(location.pathname);
      previousPathnameRef.current = location.pathname;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, outlet]
  );

  return (
    <PageViewTrackContext.Provider value={null}>
      {children}
    </PageViewTrackContext.Provider>
  );
};
