import {
  Customer,
  Document,
  DocumentCategory,
  DocumentStatus,
  DocumentType,
} from '@smart/adb-shared';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { getFormattedFiltersWithDisplayName } from '@ui/library/helpers/filters';
import { useNotificationContext } from 'contexts/notification-context';
import { useTranslation } from 'react-i18next';
import { FilterProp } from '../molecules/FilterDropdown/FilterDropdown.config';
import { UploadType } from './Documents.config';
import './Documents.scss';
import {
  useDocumentBulkDownloadMutation,
  useLoadDocumentsLazyQuery,
} from './queries.generated';
import { DocumentFilters, FilterTypeValue } from './Search/Search.config';
import { useDocumentLazyQuery } from './ViewDocument/queries.generated';

const filterDocuments = (
  documents: Document[],
  searchQuery: string,
  filterQuery: FilterTypeValue[] | undefined,
  sortByNew: boolean = true
): Document[] => {
  let result: Document[] = [...documents];

  if (sortByNew && result.length > 0) {
    const filterWithoutDateModified = result.filter(
      (item) => !item.dateModified
    );

    const sortedDocuments =
      result
        .filter((item) => item.dateModified)
        .sort(
          (firstItem, secondItem) =>
            new Date(secondItem.dateModified).getTime() -
            new Date(firstItem.dateModified).getTime()
        ) ?? [];

    if (sortedDocuments && sortedDocuments.length > 0) {
      result = [...sortedDocuments, ...filterWithoutDateModified];
    }
  }

  if (filterQuery && filterQuery?.length > 0 && result.length > 0) {
    result = result
      .filter((document) => {
        const filterTypeData = filterQuery.find(
          (item) => item.type === DocumentFilters.TYPE
        );

        if (filterTypeData && filterTypeData?.queryValue !== document?.type) {
          return false;
        }

        return document;
      })
      .filter((document) => {
        const filterTypeData = filterQuery.find(
          (item) => item.type === DocumentFilters.CATEGORY
        );

        if (
          filterTypeData &&
          filterTypeData?.queryValue !== document?.category
        ) {
          return false;
        }

        return document;
      })
      .filter((document) => {
        const filterTypeData = filterQuery.find(
          (item) => item.type === DocumentFilters.STATUS
        );

        if (filterTypeData && filterTypeData?.queryValue !== document?.status) {
          return false;
        }

        return document;
      });
  }

  if (searchQuery && searchQuery !== '' && result.length > 0) {
    result = result.filter(
      (document) =>
        document.name.toLowerCase().includes(searchQuery.toLowerCase()) ??
        document
    );
  }
  return result;
};

interface DocumentsContextProps extends PropsWithChildren {
  customer?: Customer;
  customerLoading?: boolean;
  uploadType?: UploadType;
}

interface Filters {
  category: FilterProp[];
  status: FilterProp[];
  type: FilterProp[];
}

interface DocumentsContextValue {
  documents: Document[];
  documentsLoading: boolean;
  documentsRefresh: () => void;
  documentsBulkDownloadLoading: boolean;
  documentsBulkDownload: () => void;
  documentsSearchQuery: string;
  documentsFilterQuery?: FilterTypeValue[];
  documentsFilterSet: (
    filter: FilterProp | undefined,
    type: DocumentFilters
  ) => void;
  documentsSearchQuerySet: (value: string) => void;
  filters: Filters;
  documentLoad: (customerEmail: string, document: Document) => void;
  documentLoading: boolean;
  customerLoading?: boolean;
  customer?: Customer;
  bpId?: string;
  uploadType?: UploadType;
}

const DocumentsContext = React.createContext<DocumentsContextValue>({
  documents: [],
  documentsLoading: false,
  documentsRefresh: () => {},
  documentsBulkDownloadLoading: false,
  documentsBulkDownload: () => {},
  documentsSearchQuery: '',
  documentsFilterSet: () => {},
  documentsSearchQuerySet: () => {},
  filters: {
    category: [],
    status: [],
    type: [],
  },
  documentLoad: () => {},
  documentLoading: false,
  customerLoading: false,
});

export const DocumentsContextProvider = ({
  children,
  customer,
  customerLoading,
  uploadType,
}: DocumentsContextProps) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotificationContext();

  const [documentsSearchQuery, setDocumentsSearchQuery] = useState<string>('');
  const [documentsFilterQuery, setDocumentsFilterQuery] = useState<
    FilterTypeValue[] | undefined
  >(undefined);

  const [loadDocument, { loading: documentLoading }] = useDocumentLazyQuery();

  const [loadDocuments, { data, loading: documentsLoading }] =
    useLoadDocumentsLazyQuery({
      fetchPolicy: 'network-only',
      onError: (errors) => {
        if (
          errors.message.includes('signal is aborted') ||
          errors.message.includes('aborted a request')
        ) {
          return;
        }
        addError({
          label: t('customer.documents.create.error'),
          message: t('customer.documents.create.load_document_error'),
        });
      },
    });

  const documents = filterDocuments(
    data?.documentSearch.documents ?? [],
    documentsSearchQuery,
    documentsFilterQuery
  );

  const documentsRefresh = useCallback(() => {
    loadDocuments({
      variables: {
        input: {
          ...(customer?.uuid && { customerId: customer.uuid }),
          ...(customer?.userId && { userId: customer.userId }),
        },
      },
    });
  }, [loadDocuments, customer?.uuid, customer?.userId]);

  const documentLoad = useCallback(
    (customerEmail: string, document: Document) => {
      loadDocument({
        variables: {
          input: {
            customerEmail,
            id: document.id,
          },
        },
        fetchPolicy: 'network-only',
        onCompleted: (d) => {
          const alink = window.document.createElement('a');
          alink.href = `data:${d.document.type};base64, ${d.document.file}`;
          alink.download = document.name;
          alink.click();

          addSuccess({
            label: t('customer.documents.create.success'),
            message: t(
              'customer.documents.notification.download_document_success'
            ),
          });
        },
        onError: () => {
          addError({
            label: t('customer.documents.notification.error_title'),
            message: t('customer.documents.notification.view_document_error'),
            persist: true,
          });
        },
      });
    },
    [addError, addSuccess, loadDocument, t]
  );

  useEffect(() => {
    documentsRefresh();
  }, [customer?.uuid, customer?.userId, documentsRefresh]);

  const [downloadDocumentsBulk, { loading: documentsBulkDownloadLoading }] =
    useDocumentBulkDownloadMutation({
      variables: {
        input: {
          customerId: customer?.userId ?? '',
          documents: documents.map((d) => String(d.id)),
        },
      },
    });

  const documentsBulkDownload = useCallback(async () => {
    const res = await downloadDocumentsBulk();

    if (res.data?.documentBulkDownload?.signedUrl) {
      const alink = document.createElement('a');
      alink.href = res.data.documentBulkDownload.signedUrl;
      alink.download = t('customer.documents.bulk_filename');
      alink.click();
    }
  }, [downloadDocumentsBulk, t]);

  const documentsFilterSet = useCallback(
    (filter: FilterProp | undefined, type: DocumentFilters): void => {
      if (filter) {
        const filterValue: FilterTypeValue = {
          displayName: filter?.displayName,
          queryValue: filter?.queryValue,
          type,
        };

        if (documentsFilterQuery === undefined) {
          setDocumentsFilterQuery([filterValue]);
          return;
        }

        const duplicateFilter = [...documentsFilterQuery].filter(
          (item) => item.type === type
        );

        if (duplicateFilter.length > 0) {
          const queryToBeUpdated = [...documentsFilterQuery].map((item) =>
            item.type === type ? filterValue : item
          );

          setDocumentsFilterQuery(queryToBeUpdated);
          return;
        }

        setDocumentsFilterQuery([...documentsFilterQuery, filterValue]);
        return;
      }

      setDocumentsFilterQuery(
        documentsFilterQuery &&
          [...documentsFilterQuery].filter((item) => item.type !== type)
      );
    },
    [documentsFilterQuery]
  );

  const documentsSearchQuerySet = useCallback((value: string): void => {
    setDocumentsSearchQuery(value);
  }, []);

  const categoryFilter = getFormattedFiltersWithDisplayName(
    DocumentCategory
  ).map((item: FilterProp) => {
    const documentCategory = Object.keys(DocumentCategory).find(
      (key: string) => (DocumentCategory as any)[key] === item.queryValue
    );

    if (documentCategory) {
      item.displayName = t(
        `customer.documents.table.category.${documentCategory?.toLowerCase()}`
      );
    }

    return item;
  });

  const statusFilters = getFormattedFiltersWithDisplayName(DocumentStatus).map(
    (item) => {
      const documentStatus = Object.keys(DocumentStatus).find(
        (key: string) => (DocumentStatus as any)[key] === item.queryValue
      );

      if (documentStatus) {
        item.displayName = t(
          `customer.documents.table.status.${documentStatus?.toLowerCase()}`
        );
      }

      return item;
    }
  );

  const typeFilters = getFormattedFiltersWithDisplayName(DocumentType).map(
    (item) => {
      const documentType = Object.keys(DocumentType).find(
        (key: string) => (DocumentType as any)[key] === item.queryValue
      );

      if (documentType) {
        item.displayName = t(
          `customer.documents.table.type.${documentType?.toLowerCase()}`
        );
      }

      return item;
    }
  );

  const value = useMemo(
    () => ({
      documents,
      documentsLoading,
      documentsRefresh,
      documentsBulkDownloadLoading,
      documentsBulkDownload,
      documentsSearchQuery,
      documentsFilterQuery,
      documentsFilterSet,
      documentsSearchQuerySet,
      filters: {
        category: categoryFilter,
        status: statusFilters,
        type: typeFilters,
      },
      documentLoad,
      documentLoading,
      customerLoading,
      customer,
      uploadType,
    }),
    [
      documents,
      documentsLoading,
      documentsRefresh,
      documentsBulkDownload,
      documentsBulkDownloadLoading,
      documentsSearchQuery,
      documentsFilterQuery,
      documentsFilterSet,
      documentsSearchQuerySet,
      categoryFilter,
      statusFilters,
      typeFilters,
      documentLoad,
      documentLoading,
      customerLoading,
      customer,
      uploadType,
    ]
  );

  return (
    <DocumentsContext.Provider value={value}>
      {children}
    </DocumentsContext.Provider>
  );
};

export const useDocumentsContext = () => {
  const context = useContext(DocumentsContext);
  if (!context) {
    throw new Error(
      'useDocumentsContext must be used within a DocumentsContext'
    );
  }
  return context;
};
